import React, { useState } from "react"
import { graphql } from 'gatsby'
import {Col, Row, Container } from 'react-bootstrap';
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

import VideosHead from "../components/videos/videos-head"
import VideoPost from "../components/News/NewsPost/VideoPost"
import Breadcrumbs from "../components/MgServices/Breadcrumb/news-breadcrumb"
import FilterCareerVideos from "../components/videos/filter-career-videos"


const VideoMainLanding = (props) => {

  const [ search_text, setSearchText ] = useState('');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }

	const data = props.data.strapiVideosParentCategories
	const allmenus = props.data.allStrapiAllMenus.edges

	var videos = [];
	// debugger
	for(var i=0; i < props.data.allStrapiVideos.edges.length; i++){
		videos.push(props.data.allStrapiVideos.edges[i].node)
	}

	return(
		<div className="video-main-landing">
			<HeaderTwo />
			<SEO location={props.location} title={data.Meta_Title ? data.Meta_Title : data.Title} description={data.Meta_Description ? data.Meta_Description : data.Title} />
			<div className="content">
			<div className="mg-services">
				<Container>
					<Row>
						<Col xs={12}>
							<div className="mgservice-head text-center">
								<div className="service-details">
									<Breadcrumbs url="videos" tag="contact" Title={data.Name} />
									<ReactMarkdown source={data.Heading} allowDangerousHtml className="" />
								</div>
							</div>
							<VideosHead tag={data.Name}  handlechange={handlechange}/>
						    {videos.length === 0 ?
						    	<p className="no-video">No videos found, please check after some times</p> : ''
						    }

						    {search_text !== '' ? <FilterCareerVideos text={search_text} tag="videos" data={videos} menus={allmenus} pageContext={props.pageContext} /> :
						    <VideoPost data={videos} tag="parent"  menus={allmenus} pageContext={props.pageContext} /> }

						</Col>
					</Row>
				</Container>
			</div>
			</div>
			<CanHelp />
			<Footer />
		</div>
    )
}

export default VideoMainLanding


export const pageQuery = graphql`
  query VideoMainLandingQuery($slug: String!) {
      strapiVideosParentCategories(URL: {eq: $slug}) {
		Meta_Title
		Meta_Description
		Heading
    	Name
    	URL
	    videos {
	      Title
	      URL
	      Tile_Image {
	          internal {
	            description
	          }
	      }
	      Embed_Video_URL
	      Date
	      id
          imagetransforms {
            Tile_Image_Transforms
          }
	    }
      }

  	  allStrapiVideos(filter: {Videos_Parent_Category: {URL: {eq: $slug}}, Publish: {eq: true}}, sort: {order: DESC, fields: Date}) {
	    edges {
	      node {
	        URL
	        Title
	        Date
			description
	        Tile_Image {
	          internal {
	            description
	          }
	        }
	        Embed_Video_URL
	        Video_Category {
	          Build_Separate_Page
	          Choose_Category_Base_URL
	          URL
	        }
	        Videos_Parent_Category {
	          URL
	          Name
	        }
	        strapiId
          imagetransforms {
            Tile_Image_Transforms
          }
	      }
	    }
	  }


	  allStrapiAllMenus {
	    edges {
	      node {
	        URL
	        Alias
	        Sub_Parent {
	          Alias
	          URL
	          id
	        }
	        Main_Parent {
	          Alias
	          URL
	          id
	        }
	        Link_Type
	        id
	        strapiId
	      }
	    }
	  }

    }
`
